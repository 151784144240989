<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <!-- <section class="ds s-py-90 s-py-xl-150 c-gutter-60"> -->
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font __mm">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="zoom.name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="zoom.birth_date"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">နေ့နံ</span>
                      <select
                        class="form-control"
                        name="nyih_nan"
                        v-model="zoom.nyih_nan"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <!-- <input
                      type="radio"
                      name="marital_status"
                      v-model="zoom.marital_status"
                      value="Yes"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်ရှိ</span>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="zoom.marital_status"
                      value="No"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span> -->
                    <br />
                    <br />
                    <label class="form-group">
                      <span for class="mm-font __mm">အလုပ်အကိုင်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="job"
                        v-model="zoom.job"
                        required
                      />
                    </label>

                    <!-- <label class="form-group">
                      <span for class="mm-font __mm">လိပ်စာ</span>
                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        v-model="zoom.address"
                        required
                      />
                    </label> -->

                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      v-model="zoom.gender"
                      required
                    />
                    <span for class="mm-font __mm">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      v-model="zoom.gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <br /><br />
                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="zoom.phone_number"
                        required
                      />
                    </label>
                    <br /><br />
                    <label class="form-group">
                      <span for class="mm-font __mm">နေထိုင်သည့်မြို့</span>
                      <input
                        type="text"
                        class="form-control"
                        name="address"
                        v-model="zoom.address"
                        required
                      />
                    </label>
                    <p class="mm-font" id="call_service"></p>
                    <p class="mm-font __mm">
                      မေးမြန်းလိုသည့်အကြာင်းအရာကိုရေးပါ။
                    </p>
                    <label class="form-group">
                      <textarea
                        name="about"
                        v-model="zoom.about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        placeholder
                        style="color: black"
                        required
                      ></textarea>
                    </label>
                    <p class="mm-font" id="price">
                      ဉာဏ်ပူဇော်ခ {{ zoom.price }}ကျပ် ဖြစ်ပါသည်။
                      ငွေပေးချေမှုအောင်မြင်ပြီးပါက နှစ်ရက်အတွင်း
                      ပြန်လည်ဖြေကြားပေးပါမည်။
                    </p>
                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import price from "@core/i18n/resource/zoom_price.json";
import { mapGetters } from "vuex";
import { Horo, HTTP } from "@core/lib/http-common";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { makePayment, onePay } from "../js/payment";
export default {
  name: `Special`,
  data() {
    return {
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      zoom: {
        name: "",
        birth_date: "",
        nyih_nan: "",
        order_id: "0",
        gender: "",
        user_id: "0",
        about: "",
        job: "",
        baydin_sayar: " ",
        price: 0,
        address: "",
        phone_number: "",
      },
    };
  },
  created() {
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    if (this.$store.state.paymentName == " ") {
      price[0].getService.map((price) => {
        if (price.name == this.$route.params.baydinsayar) {
          this.zoom.price = price.price;
        }
      });
    } else {
      price.map((v) => {
        if (v.payment != " ") {
          if (v.payment == this.$store.state.paymentName) {
            v.getService.map((price) => {
              if (price.name == this.$route.params.baydinsayar) {
                this.zoom.price = price.price;
              }
            });
          }
        } else {
          price[0].getService.map((price) => {
            if (price.name == this.$route.params.baydinsayar) {
              this.zoom.price = price.price;
            }
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },
  methods: {
    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;

        default:
          this.precreate();
          break;
      }
    },
    async onePayProvider() {
      if (checkFormModel(this.zoom)) {
        this.disabled = true;
        this.zoom.baydin_sayar = this.$route.params.baydinsayar;
        let formdata = getFormData(this.zoom);
        formdata.append("user_phone", this.zoom.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 0,
          amount: this.zoom.price,
          category_name: "zoom",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/videocall/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.zoom.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.zoom)) {
        this.disabled = true;
        this.zoom.baydin_sayar = this.$route.params.baydinsayar;
        let formdata = getFormData(this.zoom);
        formdata.append("user_phone", this.zoom.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 0,
          amount: this.zoom.price,
          category_name: "zoom",
        };
        await makePayment([
          "1875/videocall/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.zoom.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              this.$router.push({
                name: "paymentPortal",
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    precreate() {
      if (checkFormModel(this.zoom)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 0,
          amount: this.zoom.price,
          category_name: "zoom",
        })
          .then((response) => {
            const res = response.data;
            this.zoom.order_id = res.order_id;
            this.zoom.user_id = res.user_id;
            this.zoom.baydin_sayar = this.$route.params.baydinsayar;
            let formdata = getFormData(this.zoom);
            formdata.append(
              "phone_number",
              this.getResponse.customer_info.msisdn
            );
            Horo.post("1875/videocall/horoscope", formdata).then((response) => {
              this.res_status = response.data.status;
              this.kbzpay.startPay(
                res.prepay_id,
                res.order_info,
                res.sign_app,
                ({ resultCode, lang }) => {
                  if (resultCode == 1) {
                    if (lang == "en") {
                      this.$swal("Success", "Your Purchase Success", "success");
                      this.$router.push({ name: "home" });
                    }
                    if (lang == "my") {
                      this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
                      this.$router.push({ name: "home" });
                    }
                  }
                  if (resultCode == 2) {
                    if (lang == "en") {
                      this.$swal("Failed", "Your Purchase Failed", "error");
                      this.disabled = false;
                    }
                    if (lang == "my") {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                  }
                },
                () => {
                  this.$swal("Failed", "Your Purchase Failed", "error");
                  this.disabled = false;
                }
              );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}
</style>

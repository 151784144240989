import Axios from "axios";
import envConfig from "../config/envConfig";

// With BP Pay
export let makePayment = (datas) => {
    return new Promise((resolve, reject) => {
        let horoURL = datas[0];
        let bparams = datas[1];
        let cparams = datas[2];
        let backendURL = datas[3];
        let amount = datas[4];
        let order_id;
        let payment_status = false;
        // console.log(amount);
        // chat bot url
        let getAPIUrl = envConfig.horoUrl + horoURL;
        // "https://chatbothoro.blueplanet.com.mm/api/v1/" + horoURL;
        // let getAPIUrl = "http://localhost:8000/api/v1/" + horoURL;
        // backend url
        let getBackendUrl = backendURL;
        Axios.post(getBackendUrl, cparams).then(({ data }) => {
            if (data.status == 403 || data.status == 500) {
                reject({
                    status: 500,
                });
                return;
            }
            // console.log(data);
            if (data.valid_status) {
                amount = 0;
                bparams.delete("price");
                bparams.append("price", amount);
                bparams.append("payment_status", "success");
                payment_status = true;
            }
            order_id = data.order_id;
            bparams.append("order_id", data.order_id);
            bparams.append("user_id", 1001011);

            // call chat bot url
            Axios.post(getAPIUrl, bparams, {
                auth: {
                    username: "admin2021@horo.com",
                    password: "blue0cean@2021",
                },
            }).then((response) => {
                // console.log(response.data);
                if (response.data.status == 200) {
                    resolve({
                        status: 200,
                        order_id: order_id,
                        amount: amount,
                        payment_status: payment_status,
                    });
                } else {
                    reject({
                        status: 500,
                    });
                }
            });
        });
    });
};

// OnePay Direct
export let onePay = (datas) => {
    return new Promise((resolve, reject) => {
        let horoURL = datas[0];
        let bparams = datas[1];
        let cparams = datas[2];
        let backendURL = datas[3];
        let amount = datas[4];
        let order_id;
        // console.log(amount);
        // chat bot url
        let getAPIUrl = envConfig.horoUrl + horoURL;
        // backend url
        let getBackendUrl = backendURL;
        Axios.post(getBackendUrl, cparams).then(({ data }) => {
            // console.log(data);
            if (data.status == 403 || data.status == 400) {
                reject({
                    status: 500,
                });
                return;
            }

            order_id = data.order_id;
            bparams.append("order_id", data.order_id);
            bparams.append("user_id", 1001011);

            // call chat bot url
            Axios.post(getAPIUrl, bparams, {
                auth: {
                    username: "admin2021@horo.com",
                    password: "blue0cean@2021",
                },
            }).then((response) => {
                // console.log(response.data);
                if (response.data.status == 200) {
                    resolve({
                        status: 200,
                        order_id: order_id,
                        amount: amount,
                    });
                } else {
                    reject({
                        status: 500,
                    });
                }
            });
        });
    });
};

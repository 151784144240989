<template>
  <section class="s-py-45 s-py-xl-75 c-gutter-60">
    <!-- <section class="ds s-py-90 s-py-xl-150 c-gutter-60"> -->
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <!-- <article> -->
          <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
          <!-- .entry-header -->
          <!-- <div class="entry-content"> -->
          <!-- <div class="woocommerce"> -->
          <!-- <div class="woocommerce-MyAccount-content"> -->
          <div class="row">
            <div
              v-for="item in baydinsayar"
              :key="item.name"
              class="col-12 col-md-4 my-3"
            >
              <div class="card">
                <img
                  :src="item.image"
                  class="card-img-top"
                  :alt="item.name"
                  @click="
                    $router.push({
                      name: 'zoom',
                      params: { baydinsayar: item.name },
                    })
                  "
                />
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                </div>
                <div class="card-footer">
                  <center>
                    <span
                      @click="
                        $router.push({
                          name: 'zoom',
                          params: { baydinsayar: item.name },
                        })
                      "
                      class="text-center"
                    >
                      မေးမည်
                    </span>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
          <!-- </div> -->
          <!-- </div> -->
          <!-- .entry-content -->
          <!-- </article> -->
        </main>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      baydinsayar: [
        {
          name: "ဆရာမ ဒေါက်တာစောယုနွယ်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=30.jpg",
        },
        {
          name: "ဆရာမ ယမင်းအေး",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=31.jpg",
        },
        {
          name: "ဆရာလှိုင်ဘွားဦး",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=33.jpg",
        },
        {
          name: "ဆရာကျော်ဇင်သွေး",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=34.jpg",
        },
        {
          name: "ဆရာသာထူးစံ",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=32.jpg",
        },
        {
          name: "ဆရာကောင်းဇော်သန့်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=24.jpg",
        },
        {
          name: "ဆရာမစောလှနွယ်နီ",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=9.jpg",
        },
        {
          name: "ဆရာ မင်းသိမ်းခိုင်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=1.jpg",
        },
        {
          name: "ဆရာမ ထားအဉ္ဇလီ",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=5.jpg",
        },
        {
          name: "ဆရာ အဂ္ဂမာန်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=6.jpg",
        },
        {
          name: "ဆရာမ မေသင်းကြူ",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=2.jpg",
        },
        {
          name: "ဆရာ ထူးအောင်လှိုင်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=7.jpg",
        },
        {
          name: "ဆရာမ သိမ့်နီလာဇင်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=3.jpg",
        },
        {
          name: "ဆရာမ နန်းအဉ္ဇလီမင်း",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=83.jpg",
        },
        {
          name: "ဆရာမ ဟန်နွယ်ဇင်",
          image: "https://chatbothoro.blueplanet.com.mm/image?name=4.jpg",
        },
      ],
    };
  },
};
</script>
